import React, { Children, useEffect, useState } from "react";
import logo from "../../img/logo3.png";
import { Drawer, Dropdown, List, Menu, Space } from "antd";
import ArrowRight from "../../icons/arrowRight";
import { Link, useNavigate } from "react-router-dom";
import "./styles.css";
import { dropdownMenuProduct } from "./data";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showChildDrawer, setShowChildDrawer] = useState(false);
  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };
  const navigate = useNavigate();

  const handleClickNavigate = (session, e) => {
    e.preventDefault();
    navigate('/');
    const a = document.createElement('a');
    a.setAttribute('href', session);
    const timeOut = setTimeout(() => {
      a.click();
    }, 200)
    document.removeChild(a);
    clearTimeout(timeOut);
  }

  const handleShowChildDrawer = () => {
    setShowChildDrawer(!showChildDrawer)
  }

  const item = dropdownMenuProduct.map((product, index) => {
    return (
      {
        key: `3.${index + 1}`,
        label: (
          <Link to={product.to} className="header_dropdown_item">
            <Space size={'large'} className="header_dropdown_item">
              <h3 className="header_dropdown_item-label">{product.lable}</h3>
              <ArrowRight />
            </Space>
          </Link>
        ),
        onClick: () => {
          setShowChildDrawer(false);
          setShowMenu(false);
        }
      }
    )
  })

  const mobileItems = [
    {
      key: '1',
      label: <p
        className="nav__link active-link"
        >
        Trang chủ
      </p>,
        onClick: (e) => { handleShowMenu(); handleClickNavigate("#home", e) }
    },
    {
      key: '2',
      label: <p className="nav__link" >
        Về chúng tôi
      </p>,
      onClick: (e) => { handleShowMenu(); handleClickNavigate("#about", e) }
    },
    {
      key: '3',
      label: <p className="nav__link" >
        Sản phẩm
      </p>,
      onClick: handleShowChildDrawer
    },
    {
      key: '4',
      label: <p className="nav__link" >
        Hồ sơ năng lực
      </p>,
      onClick: (e) => { handleShowMenu(); handleClickNavigate("#profile", e) }
    },
    {
      key: '5',
      label: <p
        className="nav__link"
        >
        Khách hàng, đối tác
      </p>,
      onClick: (e) => { handleShowMenu(); handleClickNavigate("#customer", e) },
    },
    {
      key: '6',
      label: <p  className="nav__link" >
        Liên hệ
      </p>,
      onClick: (e) => { handleShowMenu(); handleClickNavigate("#map", e) }
    },
  ]

  return (
    <header className="l-header" id="header">
      <nav className="nav bd-container">
        <div className="nav__logo">
          <img src={logo} alt="" />
        </div>
        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list">
            <li className="nav__item">
              <a href="#home" className="nav__link active-link" onClick={(e) => handleClickNavigate("#home", e)}>
                Trang chủ
              </a>
            </li>
            <li className="nav__item">
              <a href="#about" className="nav__link" onClick={(e) => handleClickNavigate("#about", e)}>
                Về chúng tôi
              </a>
            </li>
            <div className="nav__item">
              <Dropdown menu={{ items: item }} trigger={['hover']}>
                <a className="nav__link" onClick={(e) => e.preventDefault()}>
                  Sản phẩm
                </a>
              </Dropdown>
            </div>
            <li className="nav__item">
              <a href="#profile" className="nav__link" onClick={(e) => handleClickNavigate("#profile", e)}>
                Hồ sơ năng lực
              </a>
            </li>
            <li className="nav__item">
              <a href="#customer" className="nav__link" onClick={(e) => handleClickNavigate("#customer", e)}>
                Đối tác
              </a>
            </li>
            <li className="nav__item">
              <a href="#map" className="nav__link" onClick={(e) => handleClickNavigate("#map", e)}>
                Liên hệ
              </a>
            </li>
          </ul>
        </div>
        <div
          className="nav__toggle"
          id="nav-toggle"
          onClick={(e) => setShowMenu(!showMenu)}
        >
          <i className="bx bx-grid-alt"></i>
        </div>
        <div
          className={`nav__menu__mobile ${showMenu ? "active" : ""}`}
          id="nav-menu-mobile"
        >
          <Drawer
            placement={"left"}
            width={500}
            onClose={(e) => setShowMenu(!showMenu)}
            open={showMenu}
            className={'drawer'}
            closable={false}
          >
            <List
              dataSource={mobileItems}
              renderItem={(item) => {
                return (
                  <List.Item key={item?.key} onClick={(e) => item?.onClick(e)}>
                    <Space size={"small"} align="center" className="header_dropdown_item">
                      {item?.label}
                      <ArrowRight />
                    </Space>
                  </List.Item>
                )
              }}
            />
            <Drawer
              placement="left"
              width={500}
              onClose={handleShowChildDrawer}
              open={showChildDrawer}
              // closable={false}
              className="drawer"
              title="Sản phẩm"
            >
              <List
                dataSource={item}
                renderItem={(item) => {
                  return (
                    <List.Item onClick={item?.onClick}>
                      {item?.label}
                    </List.Item>
                  )
                }}
              />
            </Drawer>
          </Drawer>
        </div>
      </nav>
    </header>
  );
};

export default Header;
