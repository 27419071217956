import React from 'react'
import { Carousel } from 'antd';
import { data, dataMobile } from './data';
import './styles.css';
const SpeechToText = () => {
  const contentStyle = {
    margin: 0,
    textAlign: 'center',
  };
  const videoStyle = {
    margin: 0,
    // height: '480px',
    textAlign: 'center',
  };
  return (
    <section className='contact section bd-container' id='camera_ai'>
      <h2 className='section-title'>VNS BOT MEETING</h2>
      <p className='products__description'>
        giải pháp ghi âm và chuyển âm thanh thành văn bản theo thời gian thực, nhận dạng người nói
      </p>
      <Carousel autoplay>
        {data?.map((item, index) => {
          return (
            <img key={index} style={contentStyle} src={item} alt="" className='images' />
          )
        })}
      </Carousel>
      <p className='section-title'></p>
      <p className='products__description'>
        Web Client VNS BOT MEETING
      </p>
      <iframe
        className="video"
        style={videoStyle}
        src="https://www.youtube.com/embed/OukcBqB7Lx4?si=SLd7PfBrZhodSn6D"
        title="Web Client VNS BOT MEETING"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen></iframe>

    </section>
  )
}

export default SpeechToText
