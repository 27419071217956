import slide1 from "../../img/slide-web-stt-1.jpeg";
import slide2 from "../../img/slide-web-stt-2.jpeg";
import slide3 from "../../img/slide-web-stt-3.jpeg";
import slide4 from "../../img/slide-web-stt-4.jpeg";
import slide5 from "../../img/slide-web-stt-5.jpeg";
import slide6 from "../../img/slide-web-stt-6.jpeg";
import slide7 from "../../img/slide-web-stt-7.jpeg";
import slide8 from "../../img/slide-web-stt-8.jpeg";

export const data = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8
];

