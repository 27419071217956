import { useEffect } from "react";
import CameraAI from "../../components/CameraAI";

export default function CameraAiPage() {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    return (
        <>
            <CameraAI />
        </>
    )
}