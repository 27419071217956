import CameraAI from "../../components/CameraAI";
import Customer from "../../components/Customer";
import Decoration from "../../components/Decoration";
import Map from "../../components/Map";
import Products from "../../components/Products";
import Slide from "../../components/Slide";
import Home from "../../components/Home";

export default function HomePage() {
    return (
        <>
            <Home />
            <Decoration />
            <Products />
            <Slide />
            <Customer />
            <Map />
        </>
    )
}