import { Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import { MessengerChat } from "react-messenger-chat-plugin";
import { pages } from './router';

function App() {
  return (
    <div className="App">
      <Header />
      <main className="l-main">
        <Routes>
          {pages?.map(page => {
            return (
              <Route
                key={page.path}
                element={page.component}
                path={page.path}
              />
            )
          })}
        </Routes>
      </main>
      <Footer />
      <MessengerChat
        pageId="111358264078342"
        language="vi_VN"
        themeColor={"#004a99"}
        bottomSpacing={100}
        loggedInGreeting="Chào bạn! Tôi có thể giúp gì cho bạn"
        loggedOutGreeting="Chào bạn! Tôi có thể giúp gì cho bạn"
        greetingDialogDisplay={"show"}
        debugMode={true}
      />
    </div>
  );
}

export default App;
