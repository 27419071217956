import React from 'react'
import { Carousel } from 'antd';
import { data, dataDesktop, dataMobile } from './data';
import './styles.css';
const CameraAI = () => {
  const contentStyle = {
    margin: 0,
    textAlign: 'center',
  };
  const videoStyle = {
    margin: 0,
    // height: '480px',
    textAlign: 'center',
  };
  return (
    <section className='contact section bd-container' id='camera_ai'>
      <h2 className='section-title'>VNS CAMAI</h2>
      <p className='products__description'>
        Giải pháp quản lý hệ thống Camera tập trung. Được tích hợp trí tuệ nhân tạo trong phân tích hình ảnh trực tiếp
      </p>
      <Carousel autoplay>
        {data?.map((item, index) => {
          return (
            <img key={index} style={contentStyle} src={item} alt="" className='images' />
          )
        })}
      </Carousel>
      <p className='section-title'></p>
      <p className='products__description'>
        Mobile App VNS CamAI
      </p>
      <Carousel autoplay>
        {dataMobile?.map((item, index) => {
          return (
            <img key={index} style={contentStyle} src={item} alt="" className='images' />
          )
        })}
      </Carousel>
      <p className='section-title'></p>
      <p className='products__description'>
        Desktop App VNS CamAI
      </p>
      <Carousel autoplay>
        {dataDesktop?.map((item, index) => {
          return (
            <img key={index} style={contentStyle} src={item} alt="" className='images' />
          )
        })}
      </Carousel>
      <p className='products__description'></p>
      <p className='products__description'>
        Web Client VNS CamAI
      </p>
      <iframe
        className="video"
        style={videoStyle}
        src="https://www.youtube.com/embed/xepq4ZEPxyQ?si=lnYtSjEieuvpHHZF"
        title="Web Client VNS CamAI"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen></iframe>
    </section>
  )
}

export default CameraAI