import slide1 from "../../img/slide-web-1.png";
import slide2 from "../../img/slide-web-2.png";
import slide3 from "../../img/slide-web-3.png";
import slide4 from "../../img/slide-web-4.png";
import slide5 from "../../img/slide-web-5.png";
import slide6 from "../../img/slide-web-6.png";
import slide8 from "../../img/slide-mobile-1.png";
import slide9 from "../../img/slide-mobile-2.png";
import slide10 from "../../img/slide-desktop-1.png";
import slide11 from "../../img/slide-desktop-2.png";

export const data = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
];
export const dataMobile = [
    slide8,
    slide9,
];
export const dataDesktop = [
    slide10,
    slide11,
];

