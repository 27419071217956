import { router } from "./config/router";
import HomePage from "./pages/home";
import CameraAiPage from "./pages/cameraAi";
import SpeechToTextPage from "./pages/speechToText";

export const pages = [
    {
        path: router.home, component: <HomePage />
    },
    {
        path: router.camera_ai, component: <CameraAiPage />
    },
    // Bổ xung các page khác
    {
        path: router.his, component: <CameraAiPage />
    },
    {
        path: router.speechToText, component: <SpeechToTextPage />
    },
    {
        path: router.thamDinhGia, component: <CameraAiPage />
    },
    {
        path: router.quanLyTaiSan, component: <CameraAiPage />
    },
    {
        path: router.coreGiaoDuc, component: <CameraAiPage />
    },
    {
        path: router.congChungGiaoDichDamBao, component: <CameraAiPage />
    },
]