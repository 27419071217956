import React from 'react'
const Home = () => {
  return (
    <section className="home section" id="home">
    <div className="home__container bd-container bd-grid">
      <div className="home__data">
        <h2 className="home__title">Giải pháp công nghệ vì <br /> con người</h2>
        <p className="home__description">
        "Luôn tận tâm, luôn trung thực, luôn gắn kết"
        </p>
        <a href="#" className="button">Về chúng tôi</a>
      </div>
    </div>
  </section>
  )
}

export default Home