import { useEffect } from "react";
import SpeechToText from "../../components/SpeechToText";

export default function SpeechToTextPage() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <SpeechToText />
    )
}